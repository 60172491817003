<template>
  <section class="section">
    <h4 class="title has-text-centered is-4">Configuration</h4>

    <b-loading :is-full-page="false" v-model="loadingForm"></b-loading>

    <div class="columns">
      <div class="column is-6">
        <form @submit="handleSubmit">
          <div class="columns" v-for="(config, index) in formData" :key="index">
            <div class="column is-5">
              <b-field custom-class="is-small">
                <b-input v-model="formData[index].key" size="is-small"></b-input>
              </b-field>
            </div>
            <div class="column is-2 has-text-centered">=</div>
            <div class="column is-5">
              <b-field custom-class="is-small">
                <b-input v-model="formData[index].value" size="is-small"></b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-6">
              <b-button type="is-success" icon-left="check" native-type="submit">Update</b-button>
            </div>
            <div class="column is-6 has-text-right">
              <b-button @click="addEmptyField" type="is-info" icon-left="plus">Add field</b-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { Config } from '@/services/api';

export default {
  name: 'Config',
  data() {
    return {
      errors: [],
      formData: null,
      loadingForm: false,
      sent: false,
    };
  },
  mounted() {
    Config.getConfigs()
      .then((data) => {
        this.formData = Object.keys(data).map((key) => ({
          key,
          value: data[key],
        }));
        this.addEmptyField();
      });
  },
  methods: {
    addEmptyField() {
      this.formData.push({
        key: '',
        value: '',
      });
    },
    handleSubmit(event) {
      event.preventDefault();

      const data = {};

      this.formData.forEach((config) => {
        if (config.key && config.value) {
          data[config.key] = config.value;
        }
      });

      this.errors = [];
      this.loadingForm = true;

      Config.updateConfigs(data)
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
